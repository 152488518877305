<template>
  <div id="main-wrapper" class="inner-pages forex-page indices-page">
    <div class="section banner-holder">
      <div class="forex-nav">
        <div class="wrapper">
          <ul>
            <li>
              <router-link to="/forex">{{
                $t("indicesPages.innerPages.forex")
              }}</router-link>
            </li>
            <li class="active">
              <router-link to="/indices">{{
                $t("indicesPages.innerPages.indices")
              }}</router-link>
            </li>
            <li>
              <router-link to="/commodities">{{
                $t("indicesPages.innerPages.commodities")
              }}</router-link>
            </li>
            <li>
              <router-link to="/stocks">{{
                $t("indicesPages.innerPages.stocks")
              }}</router-link>
            </li>
            <li>
              <router-link to="/crypto">{{
                $t("indicesPages.innerPages.crypto")
              }}</router-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="title-holder t-white t-center center-div wow fadeIn">
        <h2>{{ $t("indicesPages.title") }}</h2>
        <p>{{ $t("indicesPages.heading") }}</p>
      </div>
    </div>
    <div class="section strip-details">
      <div class="wrapper2">
        <div class="strip-info wow fadeIn">
          <h3>
            {{ $t("indicesPages.min") }} <span>$0.74</span>
            {{ $t("indicesPages.commission") }}
          </h3>
        </div>
        <div class="strip-info wow fadeIn" data-wow-delay="0.3s">
          <h3>
            {{ $t("indicesPages.min") }} {{ $t("indicesPages.execution") }}
            <span>10ms</span>
          </h3>
        </div>
        <div class="strip-info wow fadeIn" data-wow-delay="0.6s">
          <h3>
            {{ $t("indicesPages.min") }} <span>0.0</span>
            {{ $t("indicesPages.spread") }}
          </h3>
        </div>
        <div class="strip-info wow fadeIn" data-wow-delay="0.9s">
          <h3><span>10+</span> {{ $t("indicesPages.innerPages.indices") }}</h3>
        </div>
        <div class="strip-info wow fadeIn" data-wow-delay="1.2s">
          <h3>
            {{ $t("indicesPages.upTo") }} <span>20x</span>
            {{ $t("indicesPages.leverage") }}
          </h3>
        </div>
      </div>
    </div>
    <div class="section forex-content">
      <div class="wrapper">
        <div class="title-holder t-left wow fadeIn">
          <h3>
            <strong>{{ $t("indicesPages.contract") }}</strong>
            {{ $t("indicesPages.specification") }}
          </h3>
          <p>
            {{ $t("indicesPages.pTag") }}
            <a href="mailto:support@aaafx.com">support@aaafx.com</a>
          </p>
        </div>
        <div class="table-holder home-table wow fadeIn">
          <div class="search-holder">
            <input
              type="text"
              name=""
              value=""
              placeholder="Search Instrument"
            />
          </div>
          <table>
            <tr>
              <th>{{ $t("indicesPages.instument") }}</th>
              <th>{{ $t("indicesPages.bid") }}</th>
              <th>{{ $t("indicesPages.ask") }}</th>
              <th class="">{{ $t("indicesPages.spread") }}</th>
              <th class="">{{ $t("indicesPages.size") }}</th>
              <th class="">{{ $t("indicesPages.minTrade") }}</th>
              <th class="">{{ $t("indicesPages.tickValue") }}</th>
              <th>{{ $t("indicesPages.hours") }}</th>
            </tr>
            <tr
              v-for="(spreads, key, index) in get_currencies"
              :key="key"
              :index="index"
              v-show="index < page * limit && index >= (page - 1) * limit"
            >
              <td>
                {{ key }}
              </td>
              <td>
                <span
                  :class="[
                    {
                      't-green': spreads.buy_increase_staus == 1,
                      't-red': spreads.buy_increase_staus == 0,
                    },
                  ]"
                  >{{ spreads.B }}</span
                >
              </td>
              <td>
                <span
                  :class="[
                    {
                      't-green': spreads.sell_increase_staus == 1,
                      't-red': spreads.sell_increase_staus == 0,
                    },
                  ]"
                  >{{ spreads.A }}</span
                >
              </td>
              <td class="">{{ spreads.SPREAD }}</td>
              <td class="">{{ spreads.other.Contract_Size }}</td>
              <td class="">{{ spreads.other.Min_Trade_Size }}</td>
              <td class="">{{ spreads.other.Tick_Value }}</td>
              <td class="">7:00 am - 12:00pm</td>
            </tr>
          </table>
          <div class="clearfix links">
            <!-- <a href="#" class="t-right see-link f-right"> Next</a> -->
            <pagination
              :totalRecords="Object.keys(this.get_currencies).length"
              :limit="limit"
              :pageNo="page"
            />
          </div>
        </div>

        <div class="section-small">
          <div class="accordion-holder t-left">
            <h3 v-on:click="Indices ^= true" :class="Indices ? 'active' : ''">
              {{ $t("indicesPages.trading") }}
            </h3>
            <div class="content" v-show="Indices">
              <p>
                {{ $t("indicesPages.pTag1") }}
              </p>
            </div>
            <h3 v-on:click="work ^= true" :class="work ? 'active' : ''">
              {{ $t("indicesPages.how") }}
            </h3>
            <div class="content" v-show="work">
              <p>
                {{ $t("indicesPages.content") }}
              </p>
            </div>
          </div>
        </div>

        <div class="section account-types">
          <div class="title-holder t-left wow fadeIn">
            <h3>
              {{ $t("indicesPages.account") }}
              <strong>{{ $t("indicesPages.type") }}</strong>
            </h3>
          </div>
          <div class="table-holder home-table acct-type wow fadeIn">
            <table>
              <tr>
                <th>&nbsp;</th>
                <th>{{ $t("indicesPages.ecn") }}</th>
                <th>
                  {{ $t("indicesPages.ecn") }}
                  <span>{{ $t("indicesPages.plus") }}</span>
                </th>
                <th>
                  {{ $t("indicesPages.ecn") }}
                  <span>{{ $t("indicesPages.zulu") }}</span>
                </th>
              </tr>
              <tr>
                <td>
                  {{ $t("indicesPages.deposit") }} ({{
                    $t("indicesPages.min")
                  }})
                </td>
                <td>$100</td>
                <td>$10,000</td>
                <td>$100</td>
              </tr>
              <tr>
                <td>{{ $t("indicesPages.commission") }} (per $100k)</td>
                <td>$0.99</td>
                <td>$0.74</td>
                <td>$10</td>
              </tr>
              <tr>
                <td>
                  {{ $t("indicesPages.spread") }} ({{ $t("indicesPages.min") }})
                </td>
                <td>0.0</td>
                <td>0.0</td>
                <td>0.0</td>
              </tr>
            </table>
            <div class="clearfix links">
              <router-link
                to="/account-types"
                target="_blank"
                class="t-right see-link f-right"
              >
                {{ $t("indicesPages.compare") }}
            </router-link>
            </div>
          </div>
        </div>

        <div class="section trading-plat">
          <div class="info-holder wow fadeInLeft">
            <div class="title-holder">
              <h3>
                {{ $t("indicesPages.online") }}
                <strong
                  >{{ $t("indicesPages.tradingText") }} <br />{{
                    $t("indicesPages.platform")
                  }}</strong
                >
              </h3>
            </div>
            <div class="link-holder col">
              <a href="#">
                <img
                  src="assets/images/plat-ico1.webp"
                  alt="AAAFx"
                  title="AAAFx"
                  width="69"
                  height="55"
                  class="fluid-img"
                />
                {{ $t("indicesPages.desktop") }}</a
              >
            </div>
            <div class="link-holder col">
              <a href="#">
                <img
                  src="assets/images/plat-ico2.webp"
                  alt="AAAFx"
                  title="AAAFx"
                  width="69"
                  height="55"
                  class="fluid-img"
                />
                {{ $t("indicesPages.webTrade") }}</a
              >
            </div>
            <div class="link-holder col">
              <a href="#">
                <img
                  src="assets/images/plat-ico3.webp"
                  alt="AAAFx"
                  title="AAAFx"
                  width="69"
                  height="55"
                  class="fluid-img"
                />
                {{ $t("indicesPages.android") }}</a
              >
            </div>
            <div class="link-holder col">
              <a href="#">
                <img
                  src="assets/images/plat-ico4.webp"
                  alt="AAAFx"
                  title="AAAFx"
                  width="69"
                  height="55"
                  class="fluid-img"
                />
                {{ $t("indicesPages.ios") }}</a
              >
            </div>
            <div class="btn-holder">
              <router-link to="/compare-platform" class="btn btn-bluey">
                {{ $t("indicesPages.learn") }}</router-link
              >
            </div>
          </div>
          <div class="links-holder wow fadeInRight">
            <div class="link-holder col">
              <img
                src="assets/images/plat3.webp"
                alt="AAAFx"
                title="AAAFx"
                width="237"
                height="100"
                class="fluid-img"
              />
            </div>
          </div>
        </div>

        <div class="section nega-sec">
          <div class="content-holder wow fadeInLeft">
            <div class="title-holder">
              <h3>
                <strong>{{ $t("indicesPages.negative") }}</strong>
                 {{ $t("indicesPages.protection") }}
              </h3>
              <p class="sub">
                {{ $t("indicesPages.pSubTag") }}
              </p>
              <br />
              <p>
                {{ $t("indicesPages.workTowards") }}
              </p>
              <div class="btn-holder">
                <a :href="siteurl2" target="_blank" class="btn btn-yellow">
                  {{ $t("indicesPages.live") }}</a
                >
                <a :href="siteurl2" target="_blank" class="btn btn-blue">
                  {{ $t("indicesPages.demo") }}</a
                >
              </div>
            </div>
          </div>
          <div class="img-holder t-center wow fadeInRight">
            <img
              src="assets/images/nega-img.webp"
              alt="AAAFx"
              title="AAAFx"
              width="579"
              height="433"
              class="fluid-img"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Const } from "../plugins/vue-provider-cache/const.mod";
import pagination from "@/components/shared/pagination.vue";
export default {
  name: "forex",
  data() {
    return {
      page: 1,
      limit: 8,
      Indices: true,
      work: false,
      siteurl: process.env.VUE_APP_CLIENT_PORTAL_URL,
      siteurl2: process.env.VUE_APP_CLIENT_REGISTER_URL,
    };
  },
  components: {
    pagination: pagination,
  },
  computed: {
    get_currencies() {
      try {
        // just 'feel' the pulse here so we get a reactive response
        // as the cache is not reactive
        this.$store.getters[Const.GET_HEARTBEATS]["priceFeed"];
        return this.$store.getters[Const.GET_PROVIDERS][
          "priceFeed"
        ].iCache.getIndicesAll();
      } catch (ex) {
        // console.log('OO-one [ex]', ex)
        return [];
      }
    },
  },
};
</script>
